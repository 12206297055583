@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700&display=swap');

@font-face {
	font-family: 'Sabo';
	src: url('./fonts/Sabo-Filled.otf') format('opentype');
	font-display: swap;
}
.cursor{
	cursor: pointer;
}
*,
html {
	padding: 0;
	margin: 0;
	font-family: 'Lexend', system-ui, -apple-system, BlinkMacSystemFont,
		'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
		'Helvetica Neue', sans-serif;
	box-sizing: border-box;
	overscroll-behavior: none;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #101010;
}

::-webkit-scrollbar {
	width: 12px;
	background-color: #ad5353;
}

::-webkit-scrollbar-thumb {
	background-color: #145327;
}

a {
	color: #fff;
	text-decoration: none;
	width: fit-content;
}

.app-padding {
	padding: 0 80px;
	transition: padding 0.2s linear;
}

@media screen and (max-width: 1000px) {
	.app-padding {
		padding: 0 50px;
	}
}

@media screen and (max-width: 700px) {
	.app-padding {
		padding: 0 30px;
	}
}

@media screen and (max-width: 500px) {
	.app-padding {
		padding: 0 13px;
	}
}

@media screen and (min-width: 1380px) {
	.app-padding {
		max-width: 1380px;
		margin: auto;
	}
}
